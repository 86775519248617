import type { Price } from '@webtypes/goApi';
import React from 'react';
import { getDiscountPercentLabel } from 'vivino-js/helpers/price';

interface DiscountPercentLabelProps {
  price: Price;
  className?: string;
  percentClass?: string;
}

/**
 * Just a wrapper component of getDiscountPercentLabel
 * so that direct use of dangerouslySetInnerHTML can be avoided
 */
const DiscountPercentLabel = ({ price, className, percentClass }: DiscountPercentLabelProps) => {
  const discountPercentHtmlStr = getDiscountPercentLabel(price, { percentClass }) as string;

  if (!discountPercentHtmlStr) {
    return null;
  }

  return (
    <span dangerouslySetInnerHTML={{ __html: discountPercentHtmlStr }} className={className} />
  );
};

export default DiscountPercentLabel;
