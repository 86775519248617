import { captureException } from '@vivino/js-web-common';
import React from 'react';

import styles from './externalAd.scss';

export interface ExternalAdProps {
  dataAdSlot: string;
  divId?: string;
}

export interface AdPlacement {
  div_id: string;
  ad_unit_id: string;
}

export interface ExternalPromotion {
  type: string;
  external: AdPlacement;
}

export const loadAds = () => {
  try {
    window.loadPrebidAds();
  } catch (error) {
    captureException(error);
  }
};

const ExternalAd = ({ dataAdSlot, divId }: ExternalAdProps) => {
  return <div id={divId} className={styles.adsbygoogle} data-ad-unit-id={dataAdSlot} />;
};

export default ExternalAd;
